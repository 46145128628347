// import ZoomMeeting from "./zoom-calendar-components";

import React, { Suspense } from 'react';
import ZoomCalendarButtons from './zoom-calendar-components/ZoomCalendarButtons';

// const ZoomMeeting = React.lazy(() => import('./zoom-calendar-components'));

const ZoomCalendar = ({ content }) => {

    let tempContent = JSON.parse(content?.allContent?.content)
    const { name, description } = content?.allContent
    // const meetingDetails  = {
    //     meetingNumber: "123456789",
    //     topic: "Team Meeting",
    //     startTime: "2024-12-28T10:00:00Z",
    //     duration: 60, // minutes
    //     joinUrl: "https://zoom.us/j/123456789",
    //     password: "123456",
    //     status: "not_started",
    //     signature: "your_signature_here" // Get this from your backend
    // };

    return (
        <Suspense fallback={<div>Loading...</div>}>

            <ZoomCalendarButtons
                meetingDetails={{ ...tempContent }}
                name={name}
                description={description}
            />
            {/* <ZoomMeeting meetingDetails={meetingDetails} /> */}
        </Suspense>
    );
};

export default ZoomCalendar;