import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SkillSection from '../details/SkillSection'
import { setSession, userEnroll } from '../../../redux-features/user/userSlice'
import SkillSectionLayout from '../../../layouts/SkillSectionLayout'
import { Box, useMediaQuery } from '@mui/material';
import { useStyles } from '../../../utils/styles';
import { isStatic } from '../../common/isStatic';
import { useTheme } from '@mui/material/styles';
import MobileCards from '../details/contentTypes/swiper';
import GoogleForm from '../details/contentTypes/GoogleForm'
import SwipeHint from '../details/contentTypes/SwipeHint'

export default function SkillsDetails() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const skillSections = useSelector(state => state.user?.skillSections)
    const waitTime = useSelector(state => state.user.userData?.timer) || 0
    const [nextContent, setNextContent] = useState()
    const [nextContent1, setNextContent1] = useState()
    const [introContent, setIntroContent] = useState([])
    const completedSubSkills = useSelector((state) => state.user.completedSubSkills)
    const subSkillId = useSelector((state) => state.user.subSkillId)
    const [ind, setInd] = useState(0)
    const [nextTimer, setNextTimer] = useState(waitTime || 0)
    const [showSwipeHint, setShowSwipeHint] = useState(true);

    const allEnrolled = isStatic ? completedSubSkills?.includes(subSkillId) : introContent.filter(content => { return (content.isCompleted) })?.length === introContent?.length

    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        setNextTimer(waitTime)
    }, [waitTime])

    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "Intro") })
        let temp1 = skillSections?.filter(ss => { return (ss?.name === "Assess") })
        let temp2 = skillSections?.filter(ss => { return (ss?.name === "SIMS") })
        setNextContent(temp1)
        setNextContent1(temp2)
        setIntroContent(temp)
        if (temp.length === 0) {
            navigate("/skill-detail-assess")
        }
    }, [skillSections])

    useEffect(() => {
        const hasSeenHint = localStorage.getItem('hasSeenSwipeHint');
        if (hasSeenHint) {
            setShowSwipeHint(false);
        } else {
            setTimeout(() => {
                localStorage.setItem('hasSeenSwipeHint', 'true');
                setShowSwipeHint(false);
            }, 10000);
        }
    }, []);

    const handleEnrollment = (data, index) => {
        if (introContent?.length - 1 == index) {
            if (!nextContent.length) {
                if (!nextContent1?.length) {
                    dispatch(setSession(data))
                    navigate("/skill-detail-quiz")
                    return
                }
                dispatch(userEnroll(data))
                navigate("/skill-detail-sims")
            } else {
                navigate("/skill-detail-assess")
            }
        } else {
            dispatch(userEnroll(data))
            setInd(ind + 1)
            setNextTimer(waitTime)
        }
    }

    const handleNextClick = () => {
        if (!allEnrolled) {
            handleEnrollment(introContent[ind]?.id, ind)
        } else {
            introContent[ind + 1] ? setInd(ind + 1) : navigate("/skill-detail-assess")
        }
    }
    const handlePrevClick = () => {
        ind <= 0 ? window.history.go(-1) : setInd(ind - 1)
    }
    return (
        <SkillSectionLayout
            nextTimer={nextTimer}
            page="Skill Detail"
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
            isCompleted={allEnrolled}
        >
            <Box className={classes.skillSectionContainer}>
                {
                    introContent.length === 0 ?
                        <div className="col-12 justify-content-center align-items-center">
                            <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                        </div>
                        :
                        isMobile ? (
                            <>
                                {showSwipeHint && introContent[ind]?.allContent?.type !== "GoogleForm" && (
                                    <SwipeHint />
                                )}
                                {introContent[ind]?.allContent?.type == "GoogleForm" ? (
                                    <GoogleForm handleNextClick={handleNextClick} content={introContent[ind]} />
                                ) : (
                                    <MobileCards data={introContent} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} />
                                )}
                            </>
                        ) :
                            <SkillSection content={introContent[ind]} />
                }

            </Box>
        </SkillSectionLayout>
    )
}